<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <b-overlay :show="loading">
          <iq-card>
            <template v-slot:body>
              <ValidationObserver ref="form"  v-slot="{ handleSubmit }">
                <b-form  @submit.prevent="handleSubmit(register)" >
                  <b-row>
                    <b-col lg="10" sm="12">
                      <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="organization"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                          {{ $t('org_pro.organization') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="feeName.org_id"
                            :options="organizationList"
                            id="org_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Fee Name (En)" vid="fee_name" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="fee_name"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('fee_name.fee_name')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              id="fee_name"
                              v-model="feeName.fee_name"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Fee Name (Bn)" vid="fee_name_bn" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="fee_name_bn"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('fee_name.fee_name_bn')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              id="fee_name_bn"
                              v-model="feeName.fee_name_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <div class="row">
                    <div class="col-sm-3"></div>
                    <div class="col text-right">
                      <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                      &nbsp;
                      <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                    </div>
                  </div>
                </b-form>
              </ValidationObserver>
            </template>
          </iq-card>
        </b-overlay>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { licenseRegistrationServiceBaseUrl } from '../../../../../config/api_config'
import { feeNameStore, feeNameUpdate } from '../../api/routes'
import commonAuth from '../CommonAuth'

export default {
  mixins: [commonAuth],
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      feeName: {
        fee_name: '',
        fee_name_bn: '',
        org_id: 0
      },
      loading: false
    }
  },
  created () {
    this.feeName.org_id = this.userCheck()
    if (this.id) {
      const tmp = this.getFeenameData()
      this.feeName = tmp
    }
  },
  mounted () {
    core.index()
  },
  computed: {
    organizationList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    }
  },
  methods: {
    getFeenameData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      this.loading = true
      if (this.feeName.id) {
        result = await RestApi.putData(licenseRegistrationServiceBaseUrl, `${feeNameUpdate}/${this.id}`, this.feeName)
      } else {
        result = await RestApi.postData(licenseRegistrationServiceBaseUrl, feeNameStore, this.feeName)
      }
      this.loading = false
      loadinState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('licenseRegistration/mutateLicenseRegistrationProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? 'Data updated successfully' : 'Data save successfully',
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    }
  }
}
</script>
